import {useLayoutEffect, useRef, useState} from 'react';

function useElementDimensions(deps: any[] = []) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [elementDimensions, setElementDimensions] = useState({
    elementWidth: 0,
    elementHeight: 0,
  });

  useLayoutEffect(() => {
    setElementDimensions({
      elementWidth: elementRef.current?.clientWidth || 0,
      elementHeight: elementRef.current?.clientHeight || 0,
    });
  }, [elementRef, ...deps]);

  return {elementRef, ...elementDimensions};
}

export default useElementDimensions;
