import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import vettingHubFetchers from '@/api/fetchers/vettingHub';
import useDataQuery from '@/hooks/use-data-query';

export const queryKey = 'creatorBrandSafetyVettings';

function useCreatorBrandSafetyVettingsQuery({
  brandId,
  enabled = true,
}: {
  brandId: string;
  enabled?: boolean;
}) {
  return useDataQuery<CreatorBrandSafetyVetting[]>({
    queryKey: [queryKey, brandId],
    queryFn: vettingHubFetchers.creatorBrandSafetyVettings,
    enabled,
    meta: {brandId},
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}

export default useCreatorBrandSafetyVettingsQuery;
