import React from 'react';

export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}

export type Setter<T> =
  | React.Dispatch<React.SetStateAction<T>>
  | ((value: T) => void);

export enum NavigationAction {
  Previous = -1,
  Next = 1,
}

export type DefinedPreset = {
  startDate: Date;
  endDate: Date;
  label: string;
  disabled?: boolean;
};
