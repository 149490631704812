import clsx from 'clsx';
import {MRT_Row} from 'material-react-table';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import {Button} from '@lightricks/react-design-system';
import {
  updateCreatorBrandSafetyVettingsStatus,
  updateVettingReportStatus,
} from '@/utils/cache-updaters/vettingReportStatus';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import {
  type CreatorBrandSafetyVetting,
  type CreatorBrandSafetyVettingStatus,
} from '@/types/creatorBrandSafetyVetting';
import DropdownButton from '@/components/dropdown-button';
import {Option} from '@/components/dropdown-button/DropdownButton';
import useUpdateVettingStatus from '@/hooks/mutations/brand-safety/use-update-vetting-status';
import {queryKey} from '@/hooks/queries/use-creator-brand-safety-vettings-query';
import {queryKey as vettingReportQueryKey} from '@/hooks/queries/use-vetting-report-query/useVettingReportQuery';
import useBrandId from '@/hooks/use-brand-id';
import {PLACEHOLDER_ID} from '../constants';
import styles from './StatusCell.module.scss';

const SELECT_OPTIONS: Option[] = [
  {id: 'ready_for_review', label: 'Ready for review'},
  {id: 'approved', label: 'Approved'},
  {id: 'declined', label: 'Declined'},
];

const selectableStatuses: CreatorBrandSafetyVettingStatus[] = [
  'ready_for_review',
  'approved',
  'declined',
];

type StatusCellProps = {
  row: MRT_Row<CreatorBrandSafetyVetting>;
  options?: Option[];
};

function StatusCell({row, options = SELECT_OPTIONS}: StatusCellProps) {
  const {status} = row.original;
  const updateVettingStatusMutation = useStatusCellMutation(row);

  const isPlaceholder = row.original.id === PLACEHOLDER_ID;

  const onChange = (option: {id: string}) => {
    updateVettingStatusMutation.mutateAsync({
      vettingId: row.original.id,
      status: option.id as CreatorBrandSafetyVettingStatus,
    });
  };

  const label = React.useMemo(() => {
    return options.find((option) => option.id === status)?.label ?? '';
  }, [options, status]);

  const renderMenuItem = React.useCallback(
    (option: Option) => (
      <>
        <CircleIcon
          className={clsx(
            styles.circleIcon,
            styles[`circleIcon__${option.id}`]
          )}
        />
        {option.label}
      </>
    ),
    []
  );

  return selectableStatuses.includes(row.original.status) ? (
    <DropdownButton
      disabled={isPlaceholder}
      startIcon={<CircleIcon />}
      buttonClassName={clsx(styles.button, styles[`button__${status}`])}
      renderMenuItem={renderMenuItem}
      menuProps={{
        classes: {paper: styles.menu},
        slotProps: {
          root: {
            slotProps: {
              backdrop: {
                invisible: true,
                onClick: (e) => {
                  e.stopPropagation();
                },
              },
            },
          },
        },
      }}
      menuItemProps={{classes: {root: styles.menuItem}}}
      label={label}
      options={options}
      onChange={onChange}
    />
  ) : (
    <Button
      disabled
      appearance="neutral"
      mode="filled"
      size="small"
      className={clsx(styles.button, styles.buttonInvited)}
    >
      {translate('queued')}
    </Button>
  );
}

function useStatusCellMutation(row: StatusCellProps['row']) {
  const brandId = useBrandId();

  return useUpdateVettingStatus({
    onMutate: async ({vettingId, status: newStatus}) => {
      const previousData = updateCreatorBrandSafetyVettingsStatus({
        brandId,
        vettingId,
        status: newStatus,
      });
      updateVettingReportStatus({
        creatorId: row.original.profile?.id,
        reportId: row.original.reportId,
        status: newStatus as CreatorBrandSafetyVettingStatus,
      });

      return previousData;
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData([queryKey, brandId], context);
    },
    onSettled: () => {
      const {reportId, profile} = row.original;
      queryClient.invalidateQueries({queryKey: [queryKey, brandId]});
      // invalidate the report so it can be re-fetched with the updated status
      queryClient.invalidateQueries({
        queryKey: [vettingReportQueryKey, profile?.id, reportId],
      });
    },
  });
}

export default StatusCell;
