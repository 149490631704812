import dayjs from 'dayjs';
import React, {useMemo} from 'react';
import {
  Body,
  Button,
  Checkbox,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {US_SHORT_MONTH_SHORT_DAY_YEAR_FORMAT_WITH_SEPARATOR} from '@/config/timeFormats';
import {Severity} from '@/components/creator-vetting-report/constants';
import {VettingFilters} from '@/components/creator-vetting-report/hooks/use-get-vetting-filters/types';
import DateRangePicker from '@/components/date-range-picker';
import {DateRange} from '@/components/date-range-picker/types';
import DropdownButton from '@/components/dropdown-button';
import {Option} from '@/components/dropdown-button/DropdownButton';
import styles from './ProfileVettingActions.module.scss';
import dateRangePickerPresets from './dateRangePickerPresets';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-actions';

interface ProfileVettingActionsProps {
  totalPosts: number;
  filteredPosts: number;
  vettingFilters: VettingFilters;
  onDateRangeChange: (dateRange: DateRange) => void;
  onSeverityChange: (severity: Option) => void;
  minDate?: Date;
  maxDate?: Date;
}

const SEVERITY_OPTIONS = [
  {
    id: Severity.ALL,
    labelLocaleKey: `${TRANSLATION_PREFIX}.severity-options.all`,
  },
  {
    id: Severity.HIGH,
    labelLocaleKey: `${TRANSLATION_PREFIX}.severity-options.high`,
  },
  {
    id: Severity.MEDIUM,
    labelLocaleKey: `${TRANSLATION_PREFIX}.severity-options.medium`,
  },
  {
    id: Severity.LOW,
    labelLocaleKey: `${TRANSLATION_PREFIX}.severity-options.low`,
  },
];

function ProfileVettingActions(props: ProfileVettingActionsProps) {
  const {
    totalPosts,
    filteredPosts,
    vettingFilters,
    onDateRangeChange,
    onSeverityChange,
    minDate,
    maxDate,
  } = props;
  const renderDateRangePickerInput = (isOpen: boolean) => {
    const {startDate, endDate} = vettingFilters.dateRange;
    const label = `${dayjs(startDate).format(
      US_SHORT_MONTH_SHORT_DAY_YEAR_FORMAT_WITH_SEPARATOR
    )} - ${dayjs(endDate).format(
      US_SHORT_MONTH_SHORT_DAY_YEAR_FORMAT_WITH_SEPARATOR
    )}`;
    return (
      <Button
        mode="plain"
        size="small"
        appearance="neutral"
        className={`${styles.dateRangeButton} ${isOpen ? styles.open : ''}`}
        endIcon={
          <Icon size="small" appearance="neutral" name="Navigation-Calendar" />
        }
      >
        {label}
      </Button>
    );
  };

  const renderMenuItem = (option: {id: string; label: string}) => {
    return (
      <div className={styles.menuItem}>
        <Body size="lg">{option.label}</Body>
        <Checkbox checked={option.id === vettingFilters.severity} radio />
      </div>
    );
  };

  const severityOptions = useMemo(
    () =>
      SEVERITY_OPTIONS.map((option) => ({
        ...option,
        label: translate(option.labelLocaleKey),
      })),
    []
  );

  const severityLabel = useMemo(() => {
    const baseLabel = translate(`${TRANSLATION_PREFIX}.severity`);
    const selectedSeverity = severityOptions.find(
      (severity) => severity.id === vettingFilters.severity
    );

    if (selectedSeverity) {
      return (
        <Label size="md">{`${baseLabel}: ${selectedSeverity.label}`}</Label>
      );
    }

    return <Label size="md">{baseLabel}</Label>;
  }, [vettingFilters.severity]);

  return (
    <div className={styles.profileVettingActionsContainer}>
      <Label size="md">
        {translate(`${TRANSLATION_PREFIX}.showing`, {
          filtered: filteredPosts,
          total: totalPosts,
        })}
      </Label>
      <DateRangePicker
        initialDateRange={vettingFilters.dateRange}
        renderInput={renderDateRangePickerInput}
        onChange={onDateRangeChange}
        minDate={minDate}
        maxDate={maxDate}
        showPresets
        definedPresets={dateRangePickerPresets(new Date(), minDate, maxDate)}
      />
      <DropdownButton
        label={severityLabel}
        options={severityOptions}
        onChange={onSeverityChange}
        renderMenuItem={renderMenuItem}
        menuWidth={263}
        selectedOptionIds={[vettingFilters.severity]}
        menuProps={{transformOrigin: {vertical: -8, horizontal: 'left'}}}
        menuItemProps={{classes: {selected: styles.selectedMenuItem}}}
      />
    </div>
  );
}

export default ProfileVettingActions;
