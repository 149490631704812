import React from 'react';
import {List, ListItemButton} from '@mui/material';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import {DateRange, DefinedPreset} from '../../types';
import {isSameRange} from '../../utils';

type DatePresetsProps = {
  setRange: (presetRange: DateRange) => void;
  selectedRange: DateRange;
  presets: DefinedPreset[];
};

function DatePresets({presets, setRange, selectedRange}: DatePresetsProps) {
  return (
    <List>
      {presets.map((preset) => (
        <ListItemButton
          disabled={!!preset.disabled}
          key={preset.label}
          onClick={() => setRange(preset)}
          sx={[
            isSameRange(preset, selectedRange) && {
              'backgroundColor': designSystemToken(
                'semantic.bg.brand-secondary'
              ),
              'color': designSystemToken('semantic.fg.primary'),
              '&:hover': {
                color: designSystemToken('semantic.fg.primary'),
              },
            },
            {
              padding: '16px 24px',
            },
          ]}
        >
          <Label
            size="md"
            style={{
              fontWeight: isSameRange(preset, selectedRange)
                ? 'bold'
                : 'inherit',
            }}
          >
            {preset.label}
          </Label>
        </ListItemButton>
      ))}
    </List>
  );
}

export default DatePresets;
