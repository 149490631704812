import {SortingState} from '@tanstack/table-core';
import dayjs from 'dayjs';
import {MRT_ColumnDef, MRT_RowData} from 'material-react-table';
import React from 'react';
import {useShallow} from 'zustand/react/shallow';
import {Tabs} from '@lightricks/react-design-system';
import {TabData} from '@lightricks/react-design-system/dist/components/tabs/TabsProps';
import getCreatorProfileModalUrl from '@/utils/getCreatorProfileModalUrl';
import translate from '@/utils/translate';
import {
  type CreatorBrandSafetyVettingStatus,
  type CreatorBrandSafetyVetting,
  type CompletedCreatorBrandSafetyVettingStatus,
} from '@/types/creatorBrandSafetyVetting';
import {SearchDisplayCreator} from '@/types/models/search-creators/searchCreators';
import mapCreatorsForAnalytics from '@/views/creators/search/utils/mapCreatorsForAnalytics';
import Table from '@/components/table';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import useVettingHubStore, {
  TAB_VALUES,
  vettingHubActions,
} from '@/stores/vettingHubStore';
import styles from './VettingsTable.module.scss';
import {
  CreatorCell,
  LastUpdatedCell,
  DateAddedCell,
  FlagsCell,
  StatusCell,
} from './cells';
import {COMPLETED_REPORT_STATUSES, PLACEHOLDER_ID} from './constants';

const STATUS_SORT_ORDER_MAP = new Map<CreatorBrandSafetyVettingStatus, number>([
  ['ready_for_review', 0],
  ['approved', 1],
  ['declined', 2],
  ['in_progress', 3],
  ['invited', 4],
]);

type VettingsTableProps = {
  testID?: string;
  data: CreatorBrandSafetyVetting[];
  isLoading?: boolean;
};

function VettingsTable(props: VettingsTableProps) {
  const {testID = 'vettings-table', data, isLoading} = props;
  const {navigate} = useNavigation();
  const brandId = useBrandId();
  const [tabValue, sorting] = useVettingHubStore(
    useShallow((state) => [state.activeTab, state.sorting])
  );

  const isWithinTabFilter =
    (value: string) => (creator: CreatorBrandSafetyVetting) => {
      if (value === 'all') return true;

      if (value === 'invited') {
        // map invited and in_progress to the same tab
        return ['invited', 'in_progress'].includes(creator.status);
      }

      return creator.status === value;
    };

  const appendTabCounts = (tab: TabData) => {
    return {
      ...tab,
      label: `${tab.label} (${
        data.filter(isWithinTabFilter(tab.value)).length
      })`,
    };
  };

  const tabsData: TabData[] = [
    {label: translate('all'), value: TAB_VALUES.ALL},
    {label: translate('ready-to-review'), value: TAB_VALUES.READY_FOR_REVIEW},
    {label: translate('approved'), value: TAB_VALUES.APPROVED},
    {label: translate('rejected'), value: TAB_VALUES.DECLINED},
    {label: translate('queued'), value: TAB_VALUES.INVITED},
  ];

  const tabs = tabsData.map(appendTabCounts);

  const columnAlignment = (align: 'left' | 'center' | 'right') => ({
    muiTableHeadCellProps: {align},
    muiTableBodyCellProps: {align},
  });

  const tableColumns: MRT_ColumnDef<CreatorBrandSafetyVetting>[] = [
    {
      header: translate('creator-name'),
      Header: translate('creator').toUpperCase(),
      Cell: CreatorCell,
      accessorKey: 'creatorName',
      enableSorting: true,
      ...columnAlignment('left'),
    },
    {
      header: translate('last-updated'),
      Header: translate('last-updated').toUpperCase(),
      Cell: LastUpdatedCell,
      accessorKey: 'reportUpdatedAt',
      enableSorting: true,
      ...columnAlignment('center'),
    },
    {
      header: translate('added'),
      Header: translate('added').toUpperCase(),
      Cell: DateAddedCell,
      accessorKey: 'createdAt',
      enableSorting: true,
      ...columnAlignment('center'),
    },
    {
      header: translate('flags'),
      Header: translate('flags').toUpperCase(),
      Cell: FlagsCell,
      accessorKey: 'itemCount',
      enableSorting: true,
      ...columnAlignment('center'),
    },
    {
      header: translate('status'),
      Header: translate('status').toUpperCase(),
      Cell: StatusCell,
      accessorKey: 'status',
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const valueA = rowA.getValue<CreatorBrandSafetyVettingStatus>('status');
        const valueB = rowB.getValue<CreatorBrandSafetyVettingStatus>('status');
        return (
          (STATUS_SORT_ORDER_MAP.get(valueA) ?? -1) -
          (STATUS_SORT_ORDER_MAP.get(valueB) ?? -1)
        );
      },
      ...columnAlignment('center'),
    },
  ];

  const renderData = React.useMemo(() => {
    if (isLoading) return [];

    if (data.length === 0) return [placeholderRow];

    return data.filter(isWithinTabFilter(tabValue));
  }, [data, isLoading, tabValue]);

  const isRowDisabled = (row: MRT_RowData) =>
    row.id === PLACEHOLDER_ID ||
    !COMPLETED_REPORT_STATUSES.includes(
      row.status as CompletedCreatorBrandSafetyVettingStatus
    );

  const onRowClick = (row: CreatorBrandSafetyVetting) => {
    const redirectUrl = `/vetting/creators/${row.profile.id}/report/${row.reportId}?brandId=${brandId}`;
    navigate(redirectUrl);
  };

  return (
    <div className={styles.container} data-testid={testID}>
      <Tabs
        testID={`${testID}-tabs`}
        ScrollButtonComponent="div"
        size="large"
        appearance="neutral"
        onChange={(_e, value) => {
          vettingHubActions.setActiveTab(value);
        }}
        value={tabValue}
        classes={{root: styles.tabs}}
        tabsData={tabs}
        big
      />
      <Table
        testID={`${testID}-table`}
        classes={{
          container: undefined,
        }}
        columns={tableColumns}
        data={renderData}
        isLoadingInitial={isLoading}
        showProgressBars={false}
        visibleColumns={{}}
        rowSelection={{}}
        isRowDisabled={isRowDisabled}
        sorting={sorting}
        onSorting={vettingHubActions.setSorting}
        isLoading={isLoading}
        enableVirtualization
        enableSorting
        manualSorting={false}
        onRowClick={onRowClick}
      />
    </div>
  );
}

const placeholderRow: CreatorBrandSafetyVetting = {
  id: PLACEHOLDER_ID,
  type: 'creator_brand_safety_vettings',
  reportId: '',
  status: 'ready_for_review',
  creatorName: '',
  createdAt: dayjs().subtract(8, 'day').toISOString(),
  reportUpdatedAt: dayjs().subtract(7, 'day').toISOString(),
  itemCount: 128,
  profile: {
    displayName: translate('Your First Creator'),
  },
};

export default VettingsTable;
