import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import {Grid, Paper} from '@mui/material';
import {Body, Button} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {SHORT_MONTH_DAY_YEAR_FORMAT} from '@/config/timeFormats';
import Conditional from '@/components/conditional';
import DatePresets from '@/components/date-range-picker/components/date-presets';
import Divider from '@/components/divider';
import {MARKERS} from '../../markers';
import {DateRange, Setter, NavigationAction, DefinedPreset} from '../../types';
import Month from '../month';

const TRANSLATION_PREFIX = 'components.date-range-picker';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

interface MenuProps {
  testID: string;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  hoverDay?: Date;
  firstMonth: Date;
  secondMonth: Date;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  setDateRange: Setter<DateRange>;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: (dateRange: DateRange) => void;
  showPresets?: boolean;
  definedPresets: DefinedPreset[];
}

function Menu(props: MenuProps) {
  const {
    testID,
    dateRange,
    minDate,
    maxDate,
    hoverDay,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    helpers,
    handlers,
    onClose,
    onSubmit,
    showPresets,
    definedPresets,
    setDateRange,
  } = props;

  const {startDate, endDate} = dateRange;

  const canNavigateCloser = dayjs(secondMonth).diff(firstMonth, 'month') >= 2;
  const canNavigateBack = dayjs(firstMonth).isAfter(minDate, 'month');
  const canNavigateForward = dayjs(secondMonth).isBefore(maxDate, 'month');

  const commonProps = {
    dateRange,
    minDate,
    maxDate,
    hoverDay,
    helpers,
    handlers,
  };

  return (
    <Paper elevation={0} data-testid={testID}>
      <Grid container direction="row" wrap="nowrap">
        <Conditional condition={!!showPresets && !!definedPresets.length}>
          <Grid sx={{minWidth: '144px'}}>
            <DatePresets
              selectedRange={dateRange}
              presets={definedPresets}
              setRange={setDateRange}
            />
          </Grid>
          <div>
            <Divider margin={8} vertical />
          </div>
        </Conditional>
        <Grid sx={{borderRadius: '16px'}}>
          <Grid container sx={{padding: '20px 70px'}} alignItems="center">
            <Grid item sx={{flex: 1, textAlign: 'center'}}>
              <Body size="lg">
                {startDate
                  ? dayjs(startDate).format(SHORT_MONTH_DAY_YEAR_FORMAT)
                  : translate(`${TRANSLATION_PREFIX}.start-date`)}
              </Body>
            </Grid>
            <Grid item sx={{flex: 1, textAlign: 'center'}}>
              <ArrowRightAlt color="action" />
            </Grid>
            <Grid item sx={{flex: 1, textAlign: 'center'}}>
              <Body size="lg">
                {endDate
                  ? dayjs(endDate).format(SHORT_MONTH_DAY_YEAR_FORMAT)
                  : translate(`${TRANSLATION_PREFIX}.end-date`)}
              </Body>
            </Grid>
          </Grid>
          <div>
            <Divider margin={0} />
          </div>
          <Grid container direction="row" justifyContent="center" wrap="nowrap">
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[canNavigateBack, canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
            />
            <div>
              <Divider margin={8} vertical />
            </div>
            <Month
              {...commonProps}
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, canNavigateForward]}
              marker={MARKERS.SECOND_MONTH}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Divider margin={0} />
        <Grid sx={{padding: '16px'}}>
          <Button
            testID={`${testID}--cancel-button`}
            appearance="neutral"
            mode="plain"
            size="medium"
            onClick={onClose}
          >
            {translate(`${TRANSLATION_PREFIX}.cancel`)}
          </Button>
          <Button
            testID={`${testID}--ok-button`}
            appearance="neutral"
            mode="plain"
            size="medium"
            onClick={() => onSubmit?.(dateRange)}
            disabled={!startDate || !endDate}
          >
            {translate(`${TRANSLATION_PREFIX}.apply`)}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Menu;
