import React from 'react';
import {Button, Icon, Label} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {buttonNames, screenNames} from '@/lib/delta/deltaConstants.js';
import translate from '@/utils/translate';
import {listCreatorsActions} from '@/stores/list-creators/listCreatorsStore';
import styles from './NewListButton.module.scss';

const TRANSLATION_PREFIX = 'components.list-drawer';

const handleClick = () => {
  listCreatorsActions.setActiveListAction('add-list');

  AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
    button_name: buttonNames.MY_CREATORS.NEW_LIST,
    screen_name: screenNames.MY_CREATORS.MY_CREATORS,
    flow_id: '',
    flow_name: '',
    triggered_flow_id: '',
    triggered_flow_name: '',
    screen_presentation_id:
      AnalyticsService.getActiveScreenPresented().screen_presentation_id,
    campaign_id: '',
    creator_id: '',
    tab: '',
  });
};

function NewListButton({isListItem = true}: {isListItem?: boolean}) {
  return (
    <Button
      onClick={handleClick}
      className={isListItem ? styles.container : null}
      appearance="neutral"
      mode="plain"
      size="large"
    >
      <Button
        component="div"
        icon={
          <Icon name="Actions-Add-Small1" appearance="primary" size="medium" />
        }
        appearance="neutral"
        size="small"
        mode="tinted"
      />
      <Label size="md">{translate(`${TRANSLATION_PREFIX}.new-list`)}</Label>
    </Button>
  );
}

export default NewListButton;
