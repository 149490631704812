import React, {useMemo} from 'react';
import {
  designSystemToken,
  Headline,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import Divider from '@/components/divider';
import styles from './ProfileVettingItemsScanned.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-items-scanned';

const DEFAULT_LOCALE = 'en-US';

interface ProfileVettingItemsScannedProps {
  totalItemsScanned: number;
  totalVideoMinutesScanned: number;
  totalImagesScanned: number;
  totalMediaSize: string;
}

interface ScannedItemProps {
  icon: IconName;
  title: string;
  subtitle: string;
}

function ScannedItem({icon, title, subtitle}: ScannedItemProps) {
  return (
    <div className={styles.scannedItem}>
      <Icon
        size="small"
        appearance="neutral"
        name={icon}
        color={designSystemToken('semantic.fg.secondary')}
      />
      <Headline size="sm" color={designSystemToken('semantic.fg.secondary')}>
        {title}
      </Headline>
      <Label
        size="sm"
        className={styles.subtitle}
        color={designSystemToken('semantic.fg.secondary')}
      >
        {subtitle}
      </Label>
    </div>
  );
}

function ProfileVettingItemsScanned(props: ProfileVettingItemsScannedProps) {
  const {
    totalItemsScanned,
    totalVideoMinutesScanned,
    totalImagesScanned,
    totalMediaSize,
  } = props;

  const scannedItems = useMemo(() => {
    return [
      {
        id: 'video',
        icon: 'Navigation-VideoCamera-Line' as IconName,
        title: totalVideoMinutesScanned.toLocaleString(DEFAULT_LOCALE),
        subtitle: translate(`${TRANSLATION_PREFIX}.mins`),
      },
      {
        id: 'image',
        icon: 'Navigation-Photos-Light' as IconName,
        title: totalImagesScanned.toLocaleString(DEFAULT_LOCALE),
        subtitle: translate(`${TRANSLATION_PREFIX}.images`),
      },
      {
        id: 'text',
        icon: 'Navigation-Projects' as IconName,
        title: totalMediaSize,
        subtitle: translate(`${TRANSLATION_PREFIX}.media`),
      },
    ];
  }, [totalVideoMinutesScanned, totalImagesScanned]);

  return (
    <div className={styles.profileVettingItemsScannedContainer}>
      <div className={styles.header}>
        <Headline size="xl" color={designSystemToken('semantic.fg.primary')}>
          {totalItemsScanned.toLocaleString()}
        </Headline>
        <Label size="xl" color={designSystemToken('semantic.fg.primary')}>
          {translate(`${TRANSLATION_PREFIX}.content-scanned`)}
        </Label>
      </div>
      <div className={styles.itemsScanned}>
        {scannedItems.map((scannedItem, index) => (
          <div key={scannedItem.id} className={styles.itemsScannedContainer}>
            <ScannedItem {...scannedItem} />
            {index < scannedItems.length - 1 ? (
              <Divider margin={8} vertical height="60%" />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfileVettingItemsScanned;
