import {MRT_Row} from 'material-react-table';
import React from 'react';
import {Label, designSystemToken} from '@lightricks/react-design-system';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import {PLACEHOLDER_ID} from '../constants';

function FlagsCell({row}: {row: MRT_Row<CreatorBrandSafetyVetting>}) {
  const isPlaceholder = row.original.id === PLACEHOLDER_ID;
  const style = {
    color: isPlaceholder ? designSystemToken('semantic.fg.disabled') : null,
  };

  return (
    <Label size="md" sx={style}>
      {row.original.itemCount}
    </Label>
  );
}

export default FlagsCell;
