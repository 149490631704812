import {useMutation} from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import queryClient from '@/utils/reactQueryClient';
import {CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import vettingUpdaters from '@/api/updaters/vetting';
import {queryKey as resultsQueryKey} from '@/hooks/queries/use-creator-brand-safety-vettings-query';

dayjs.extend(utc);

function useCreateBrandSafetyVetting({brandId}: {brandId: string}) {
  const mutationKey = ['vetting', 'create'];

  return useMutation({
    mutationKey,
    mutationFn: vettingUpdaters.create,
    onMutate: async (data) => {
      const previousData = queryClient.getQueryData([resultsQueryKey, brandId]);

      queryClient.setQueryData(
        [resultsQueryKey, brandId],
        (old: {data: CreatorBrandSafetyVetting[]} | undefined) => {
          const newVetting = {
            id: `temp_${data.creator.email}`,
            reportId: `temp_${data.creator.email}`,
            type: 'creator_brand_safety_vettings' as const,
            status: 'invited' as const,
            creatorName: data.creator.username ?? '',
            createdAt: dayjs().utc().format(),
            reportUpdatedAt: null,
            itemCount: null,
            profile: {
              displayName: data.creator.fullName,
            },
          };
          return old?.data && {...old, data: [...old.data, newVetting]};
        }
      );

      return previousData;
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData([resultsQueryKey, brandId], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([resultsQueryKey, brandId]);
    },
  });
}

export default useCreateBrandSafetyVetting;
