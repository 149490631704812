import dayjs from 'dayjs';
import {capitalize} from 'lodash';
import {MRT_Row} from 'material-react-table';
import React from 'react';
import {Icon, Label} from '@lightricks/react-design-system';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';

function LastUpdatedCell({row}: {row: MRT_Row<CreatorBrandSafetyVetting>}) {
  return (
    <Label size="sm">
      {row.original.reportUpdatedAt === null ? (
        <Icon name="Features-Duration" size="medium" appearance="disabled" />
      ) : (
        capitalize(dayjs(row.original.reportUpdatedAt).fromNow())
      )}
    </Label>
  );
}

export default LastUpdatedCell;
