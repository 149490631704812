import React from 'react';
import {Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import {VettableCreators} from '@/types/vettableCreatorsResponse';
import Page from '@/components/page';
import useCreatorBrandSafetyVetting from '@/hooks/queries/use-creator-brand-safety-vettings-query';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useVettableCreatorsQuery from '@/hooks/queries/use-vettable-creators-query';
import useBrandId from '@/hooks/use-brand-id';
import styles from './VettingHub.module.scss';
import SearchSection from './components/search/search-section';
import VettingsTable from './components/vettings-table';

type VettingHubProps = {
  tableData?: CreatorBrandSafetyVetting[];
};

function VettingHub(props: VettingHubProps) {
  const brandId = useBrandId();
  const {data: vettingsResponseData, isLoading} = useCreatorBrandSafetyVetting({
    brandId,
  });

  const {subscription} = useSubscriptionQuery({
    brandId,
  });

  const {tableData = vettingsResponseData} = props;

  const [searchTerm, setSearchTerm] = React.useState<string | undefined>(
    undefined
  );

  const {data: searchResults, isFetching: isSearching} =
    useVettableCreatorsQuery({searchTerm});

  const onSubmitSearch = (value: string) => {
    setSearchTerm(value);
  };

  const onResetSearch = () => {
    setSearchTerm(undefined);
  };

  return (
    <Page
      id="vetting-hub"
      className={styles.page}
      childrenClassName={styles.vettingHubContainer}
      testID="vetting-hub"
    >
      <SearchSection
        darkMode={subscription.isSafeCollab}
        searchResults={searchResults as VettableCreators}
        onSubmitSearch={onSubmitSearch}
        onResetSearch={onResetSearch}
        isLoading={isSearching}
      />
      <Headline size="md" sx={{mt: 3}}>
        {translate('my-vetting-list')}
      </Headline>
      <VettingsTable data={tableData ?? []} isLoading={isLoading ?? false} />
    </Page>
  );
}

export default VettingHub;
