import {SortingState} from '@tanstack/table-core';
import {create} from 'zustand';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';

type VettingHubTabValue = CreatorBrandSafetyVetting['status'] | 'all';

export const TAB_VALUES: {[key: string]: VettingHubTabValue} = {
  ALL: 'all',
  READY_FOR_REVIEW: 'ready_for_review',
  APPROVED: 'approved',
  DECLINED: 'declined',
  INVITED: 'invited',
};

type VettingHubState = {
  activeTab: VettingHubTabValue;
  sorting: SortingState;
};

const DEFAULT_STATE: VettingHubState = {
  activeTab: 'all',
  sorting: [{desc: false, id: 'status'}],
};

const vettingHubStore = create<VettingHubState>(() => DEFAULT_STATE);

const setActiveTab = (activeTab: VettingHubTabValue) => {
  vettingHubStore.setState({activeTab});
};

const setSorting = (sorting: SortingState) => {
  vettingHubStore.setState({sorting});
};

export const vettingHubActions = {setActiveTab, setSorting};

export default vettingHubStore;
