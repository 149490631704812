import {sortBy} from 'lodash';
import {
  type VettableCreators,
  type VettableCreator,
} from '@/types/vettableCreatorsResponse';

// Helper function to normalize URLs by removing trailing slashes
function normalizeUrl(url: string | undefined): string {
  return url?.replace(/\/+$/, '') ?? '';
}

// Priority mapping for provider sorting
export const providerPriority: Record<string, number> = {
  instagram: 1,
  tiktok: 2,
  youtube: 3,
  facebook: 4,
  other: 5,
};

// Check if a creator’s account is already related in the combined results
function isCreatorRelated(
  creator: VettableCreator,
  combinedCreators: VettableCreator[]
): boolean {
  return Boolean(
    combinedCreators.some((cc) =>
      cc.relatedAccounts?.some(
        (ra) => normalizeUrl(ra.url) === normalizeUrl(creator.accountUrl)
      )
    ) ||
      creator.relatedAccounts?.some((ra) =>
        combinedCreators.some(
          (cc) => normalizeUrl(cc.accountUrl) === normalizeUrl(ra.url)
        )
      )
  );
}

// Main function to combine related creators
function combineRelatedCreators(
  data: VettableCreators
): VettableCreators<VettableCreator> {
  const combinedCreators: VettableCreator[] = [];

  const sortedOffPlatform = sortBy(
    data.offPlatform,
    (creator) => providerPriority[creator.provider ?? 'other']
  );

  const onPlatformUsernames = data.onPlatform.flatMap((creator) =>
    creator.accounts?.map((acc) => acc.username)
  );

  sortedOffPlatform.forEach((externalCreator) => {
    // Check if there’s an onboarded creator with a matching username
    const matchingOnPlatformCreator = data.onPlatform.find((creator) =>
      creator.accounts?.some((acc) => acc.username === externalCreator.username)
    );

    if (matchingOnPlatformCreator) {
      // If a match is found, add the external creator's account to the onboarded creator's accounts
      if (
        externalCreator.provider &&
        externalCreator.username &&
        externalCreator.accountUrl
      ) {
        matchingOnPlatformCreator.accounts = [
          ...(matchingOnPlatformCreator.accounts || []),
          {
            platform: externalCreator.provider,
            username: externalCreator.username,
            account_url: externalCreator.accountUrl,
          },
        ];
      }
      // Skip adding this external creator to combinedCreators as they're already onboarded
      return;
    }

    // If not onboarded, and not already related, add the external creator to combinedCreators
    if (!isCreatorRelated(externalCreator, combinedCreators)) {
      combinedCreators.push(externalCreator);
    }
  });

  // Return the modified data with combined creators in offPlatform
  return {...data, offPlatform: combinedCreators};
}

export default combineRelatedCreators;
