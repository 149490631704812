import {useFlags} from 'launchdarkly-react-client-sdk';
import {uniq} from 'lodash';
import React from 'react';
import {Box} from '@mui/material';
import {
  Avatar,
  Body,
  Button,
  Icon,
  Input,
  Label,
  Modal,
  designSystemToken,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {VettableCreator} from '@/types/vettableCreatorsResponse';
import SocialNetworkIcon from '@/components/social-network-icon';
import styles from './CreatorSearchResult.module.scss';
import SocialIconBadge from './SocialIconBadge';
import {providerPriority} from './combineRelatedCreators';

type CreatorSearchResultProps = {
  creator: VettableCreator;
  avatarSrc: string | undefined;
  isAdded: boolean;
  onClickAdd: (creator: VettableCreator) => void;
};

function CreatorSearchResult(props: CreatorSearchResultProps) {
  const {creator, avatarSrc, isAdded, onClickAdd} = props;
  const onClick = () => onClickAdd(creator);

  const onPlatformAccounts = creator.accounts?.sort((a, b) => {
    return providerPriority[a.platform] - providerPriority[b.platform];
  });

  const platform = onPlatformAccounts
    ? onPlatformAccounts[0].platform
    : creator.provider;

  const relatedProviders = onPlatformAccounts
    ? onPlatformAccounts?.map((a) => a.platform).slice(1)
    : creator.relatedAccounts?.map((a) => a.provider);

  const onSubmitEmailModal = (value: string) => {
    onClickAdd({...creator, email: value});
  };

  const renderConditionalComponent = () => {
    if (creator.isMinor) {
      return <MinorWarningComponent />;
    }
    if (creator.email === null && !brightDataPoc) {
      return <EmailNotConnected onSubmit={onSubmitEmailModal} />;
    }
    return null;
  };

  const {brightDataPoc} = useFlags();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.avatarContainer}>
          <Avatar
            size="lg"
            type="picture"
            background="none"
            src={avatarSrc}
            className={styles.avatar}
          />
          <SocialIconBadge platform={platform} className={styles.socialIcon} />
        </div>
        <div className={styles.creatorInfo}>
          <Label size="lg">
            @{creator.username ?? creator.accounts?.[0].username ?? ''}
          </Label>
          <div className={styles.creatorDetails}>
            <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
              {creator.fullName}
            </Body>
            {relatedProviders && relatedProviders.length > 0 && (
              <AlsoFoundOn relatedProviders={relatedProviders} />
            )}
          </div>
        </div>
        {isAdded ? (
          <AddButton isAdded label={translate('added')} />
        ) : (
          <AddButton
            label={translate('add')}
            onClick={onClick}
            disabled={
              (creator.email === null && !brightDataPoc) || !!creator.isMinor
            }
          />
        )}
      </div>
      {renderConditionalComponent()}
    </>
  );
}

type AddButtonProps = {
  label: string;
  onClick?: (e: React.UIEvent) => void;
  isAdded?: boolean;
  disabled?: boolean;
};

function AddButton(props: AddButtonProps) {
  const {label, onClick, isAdded = false, disabled} = props;

  const color = isAdded ? 'brand' : 'neutral-secondary';
  const startIcon = isAdded ? (
    <Icon name="Actions-Accept" size="small" appearance="brand" />
  ) : undefined;

  return (
    <Button
      appearance={isAdded ? 'brand' : 'neutral'}
      mode={isAdded ? 'plain' : 'filled'}
      size="small"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
    >
      <Label size="md" color={designSystemToken(`semantic.fg.${color}`)}>
        {label}
      </Label>
    </Button>
  );
}

function AlsoFoundOn({
  relatedProviders,
}: {
  relatedProviders: Array<VettableCreator['provider']>;
}) {
  const visibleProviders: typeof relatedProviders = ['tiktok', 'instagram'];
  const renderedProviders = uniq(relatedProviders).filter((provider) => {
    return visibleProviders.includes(provider);
  });

  return (
    <Body
      size="sm"
      color={designSystemToken('semantic.fg.secondary')}
      sx={{display: 'flex', alignItems: 'flex-end', gap: '2px'}}
    >
      {translate('also-on')}
      <Box sx={{display: 'flex', gap: '2px'}}>
        {renderedProviders.map((provider) => (
          <SocialNetworkIcon
            key={provider}
            name={provider}
            width={18}
            height={18}
            color={designSystemToken('semantic.fg.secondary')}
          />
        ))}
      </Box>
    </Body>
  );
}

type EmailNotConnectedProps = {
  onSubmit: (val: string) => void;
};

function EmailNotConnected({onSubmit}: EmailNotConnectedProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  const handlePrimaryButtonClick = () => {
    onSubmit(value);
    setIsOpen(false);
  };

  return (
    <Box className={styles.emailModalContainer}>
      <Body size="md">
        {translate('This creator’s email is not connected')},
      </Body>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        sx={{p: 0, pl: '4px'}}
        appearance="neutral"
        mode="plain"
        size="small"
      >
        <Label size="md" underline>
          {translate('add email to continue')}
        </Label>
      </Button>
      <Modal
        open={isOpen}
        showCloseButton
        size="large"
        header={translate("Add creator's email")}
        footerAlignment="right"
        textAlignment="left"
        primaryButtonText={translate('add')}
        handlePrimaryButtonClick={handlePrimaryButtonClick}
        handleClose={() => setIsOpen(false)}
      >
        <Input
          placeholder={translate('email-placeholder')}
          inputProps={{value}}
          fullWidth
          className={styles.emailModalInput}
          onInputChange={(val: string) => {
            setValue(val);
          }}
        />
      </Modal>
    </Box>
  );
}

function MinorWarningComponent() {
  return (
    <Box className={styles.emailModalContainer}>
      <Body size="md">
        {translate(
          "This creator can't be vetted due to an age restriction or unverified age."
        )}
      </Body>
    </Box>
  );
}

export default CreatorSearchResult;
