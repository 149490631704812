import clsx from 'clsx';
import {MRT_Row} from 'material-react-table';
import React from 'react';
import {Avatar, Label} from '@lightricks/react-design-system';
import {type CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import styles from './CreatorCell.module.scss';

function CreatorCell({row}: {row: MRT_Row<CreatorBrandSafetyVetting>}) {
  const src = row.original.profile.imageUrl;

  return (
    <div className={styles.container}>
      <Avatar
        size="md"
        type={src ? 'picture' : 'placeholder'}
        src={src}
        className={clsx(
          styles.avatar,
          row.original.isLiveTracking && styles.avatarLiveTracking
        )}
      />
      <Label size="sm">{row.original.profile.displayName}</Label>
    </div>
  );
}

export default CreatorCell;
