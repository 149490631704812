import dayjs from 'dayjs';
import translate from '@/utils/translate';
import {DefinedPreset} from '@/components/date-range-picker/types';
import {isSameRange} from '@/components/date-range-picker/utils';

function isPresetDisabled(
  preset: DefinedPreset,
  minDate?: Date,
  maxDate?: Date
): boolean {
  if (dayjs(preset.startDate).isAfter(preset.endDate)) {
    return true;
  }
  if (minDate && maxDate) {
    if (isSameRange(preset, {startDate: minDate, endDate: maxDate})) {
      return false;
    }
    return !(
      dayjs(preset.startDate).isAfter(minDate) &&
      dayjs(preset.endDate).isBefore(maxDate)
    );
  }
  if (minDate) {
    return !dayjs(preset.startDate).isAfter(minDate);
  }
  if (maxDate) {
    return !dayjs(preset.endDate).isBefore(maxDate);
  }
  return false;
}

const dateRangePickerPresets = (
  date: Date,
  minDate?: Date,
  maxDate?: Date
): DefinedPreset[] => {
  const presets = [
    {
      label: translate('Full Range'),
      startDate: minDate,
      endDate: maxDate,
    },
    {
      label: translate('Last Week'),
      startDate: dayjs(date).subtract(1, 'week').toDate(),
      endDate: dayjs(maxDate).startOf('day').toDate(),
    },
    {
      label: translate('Last Month'),
      startDate: dayjs(date).subtract(1, 'month').toDate(),
      endDate: dayjs(maxDate).startOf('day').toDate(),
    },
    {
      label: translate('Last 6 Months'),
      startDate: dayjs(date).startOf('day').subtract(6, 'month').toDate(),
      endDate: dayjs(maxDate).startOf('day').toDate(),
    },
    {
      label: translate('Last Year'),
      startDate: dayjs(date).subtract(1, 'year').toDate(),
      endDate: dayjs(maxDate).startOf('day').toDate(),
    },
  ] as DefinedPreset[];

  return presets.map((preset) => {
    return {
      ...preset,
      disabled: isPresetDisabled(preset, minDate, maxDate),
    };
  }) as DefinedPreset[];
};

export default dateRangePickerPresets;
